import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const BuyOnlinePage = () => (
  <Layout
    pageWrapperClassName="buy-online-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Buy Essay Online - Order Essay Now "
      meta={[
        {
          name: 'description',
          content: 'Looking for a reliable place to buy essays online? TakeAwayEssay.com 📝 is the perfect choice! Professional writers, 24/7 customer support, quality guarantees, and excellent reviews – all this makes TakeAwayEssay.com 📝 the ultimate destination for anyone looking for a Place To Buy Essays Online.',
        },

        {
          property: 'og:title',
          content: 'Buy Essay Online to Easily Overcome Your Academic Impediments | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'If you want to buy essay online, you should obligatorily read this page because it highlights the main conditions and guarantees of our writing website. If you place an order on our platform, we guarantee the highest quality of any paper that will be accomplished very quickly.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/buy-online.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Buy high-quality essays
        <br className="first-screen-section__title__br" />
        {' '}
        on any topic you need
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Our writing experts will help
        <br className="first-screen-section__heading__br" />
        {' '}
        you skyrocket your grades
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Order essay now: buy essay online
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Students from all over the globe experience definite complications with their
            assignments. This happens due to different reasons. Most students simply have
            undeveloped writing skills. However, there are other circumstances like poor time
            management, hatred for a certain discipline, problems with some professors, etc. At any
            rate, they experience difficulties and ought to find adequate solutions. One of such is
            to buy essay online on our college essay writing website.
          </p>
          <p>
            When students ask “who can write for me my essays?”, they commonly turn to our writing
            platform. We are a famous and highly reputed agency with over 10 years of successful
            experience in the sphere of academic writing. We offer more than 400 skilled and
            certified writers who compose perfect assignments of all types quickly and originally.
            Besides, our flexible price policy allows for saving a lot of your money. Therefore, you
            ensure your academic success when you buy essay on our website.
          </p>
          <h2 className="h3">Order essay online and enjoy quick results</h2>
          <p>
            One of the major reasons why so many students buy essay papers on our platform is the
            quickness of support. We propose the swiftest writing assistance on the web. We
            encourage our experts to maintain their writing skills to be always on time. They are
            able to beat the toughest deadlines. Thanks to our progressive delivery, you’re never
            late.
          </p>
          <p>
            Just tell what should be done. If your conditions are manageable, your order will be
            delivered exactly when you require it. You ought to be a realist. It’s impossible to
            accomplish a dissertation, as quickly as a personal statement, an argumentative essay or
            similar academic paper. Such big and complex projects require more time to be
            accomplished. Nevertheless, we aren’t afraid of challenges and will write even the most
            difficult assignments faster than other writing services. 97% of all orders were
            delivered before the deadline was over. Therefore, you may freely order essay online
            right here and right now. We won’t disappoint you.
          </p>
          <h2 className="h3">Buy essays written by approved experts</h2>
          <p>
            When you purchase essay on our platform, you may not worry about its quality. It’s
            always high because all the orders are written by approved writers. They all have at
            least a bachelor’s degree and are familiar with the official academic demands. There is
            nothing they won’t be able to fulfill for our customers.
          </p>
          <p>
            If you wonder where to buy essays online, consider our website. Our custom writing
            company is the best place to purchase academic assistance. Our experts easily compose
            essays, dissertations, research papers, literature reviews, laboratory reports, and
            other assignments for sale.
          </p>
          <p>
            As we have hundreds of competent writers, you’ll find experts in various academic
            subjects. Amongst such are:
          </p>
          <ul className="dot-list">
            <li>Literature</li>
            <li>Biology</li>
            <li>Medicine</li>
            <li>Mathematics</li>
            <li>Information technology</li>
            <li>Digital marketing</li>
            <li>Finance</li>
            <li>Economics</li>
            <li>Politics, etc.</li>
          </ul>
          <p>
            Our certified experts likewise provide different kinds of academic help. Purchasing our
            online services, you’re welcome to order writing, citing, outlining, referencing,
            editing, researching, creating topics, proofreading, and other functions. Get what you
            really require and enjoy the final results.
          </p>
          <h2 className="h3">Where to buy essay online on reasonable conditions?</h2>
          <p>
            Many students want to know where can they buy essay writing assistance on reasonable
            conditions. Our company is happy to ensure this necessity. We set alternatively cheap
            prices to make our assistance affordable. In fact, everything depends only on you.
            You’re welcome to customize your orders according to your needs and financial
            possibilities.
          </p>
          <p>Tell us what should be done. Specify compulsory fields, which are:</p>
          <ul className="dot-list">
            <li>Required quality</li>
            <li>Assignment type</li>
            <li>Kind of help</li>
            <li>Personal solver</li>
            <li>Length</li>
            <li>Deadline, etc.</li>
          </ul>
          <p>
            Using our calculator, determine the total cost. If you’re content with it, confirm the
            order and buy it. In case you require cheaper assistance, make some adjustments to get
            an acceptable offer. Thus, you buy essays online on your conditions.
          </p>
          <h2 className="h3">Are there some other dividends?</h2>
          <p>
            When our visitors intend to buy essay papers online on our website, they also want to
            enjoy a few more conditions and guarantees. TakeAwayEssay.com will eagerly provide them.
            Make allowances for the following dividends:
          </p>
          <ul className="dot-list">
            <li>
              Original content. We compose only 100% original assignments. Our experts excellently
              cover any topic in any discipline. As we use a reliable plagiarism checker, we easily
              detect non-original content to eliminate it from the texts.
            </li>
            <li>
              Complete safety. Collaboration with our platform is totally safe. We implement the
              most effective software, which perfectly resists any hacker attacks and viruses.
              Besides, we never share personal information about our customers with somebody else.
            </li>
            <li>
              Refunding. Once we accept your conditions, we are obliged to complete them as
              promised. We return your money if we fail you.
            </li>
          </ul>
          <p>
            We operate 24 hours round the clock. Accordingly, you can access us at any suitable time
            to place urgent orders or put important questions. We’re always ready to assist you in
            the most advantageous way. Place an instant order now and write your assignments without
            much effort.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Order your paper">
      Your road to better grades starts here.
    </CTA>

  </Layout>
);

export default BuyOnlinePage;
